<template>
  <div class="worship-view">
    <div class="container">
      <div class="worship-content">
        <div class="worship-avater"><img src="~@/assets/images/default-avatar.png" /></div>
        <div class="worship-body">
          <div class="worship-name">
            <h4>XXX 纪念馆</h4>
            <div class="worship-btn">
              <button type="button">关注</button>
              <button type="button">邀请</button>
            </div>
          </div>
          <div class="worship-date">1890.06.25-1997.05.18</div>
          <div class="worship-tabs">
            <div class="tab-router">
              <router-link to>祭祀殿堂</router-link>
            </div>
            <div class="tab-router" :class="[$route.name === 'worship-visit-life' ? 'tab-active' : '']">
              <router-link to="/hall/worship/visit-life">完善信息</router-link>
            </div>
            <div class="tab-router" :class="[$route.name === 'worship-visit-biography' ? 'tab-active' : '']">
              <router-link to="/hall/worship/visit-biography">传记</router-link>
            </div>
            <div class="tab-router" :class="[$route.name === 'worship-honor-deeds' ? 'tab-active' : '']">
              <router-link to="/hall/worship/honor-deeds">荣誉</router-link>
            </div>
            <div class="tab-router" :class="[$route.name === 'worship-visit-eulogy' ? 'tab-active' : '']">
              <router-link to="/hall/worship/visit-eulogy">悼文</router-link>
            </div>
            <div class="tab-router" :class="[$route.name === 'worship-smile-voice' ? 'tab-active' : '']">
              <router-link to="/hall/worship/smile-voice">音容笑貌</router-link>
            </div>
            <div class="tab-router" :class="[$route.name === 'worship-friends-circle' ? 'tab-active' : '']">
              <router-link to="/hall/worship/friends-circle">亲友圈</router-link>
            </div>
            <div class="tab-router" :class="[$route.name === 'worship-visit-record' ? 'tab-active' : '']">
              <router-link to="/hall/worship/visit-record">祭拜记录</router-link>
            </div>
          </div>
        </div>
      </div>

      <main style="margin-top: 26px">
        <router-view></router-view>
      </main>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
.worship-view {
  position: relative;
  width: 100%;
  z-index: 22;
  .container {
    background: url("~@/assets/images/hall/create/hall-create-bg.png") no-repeat center;
    background-size: 100% 100%;
    .worship-content {
      margin-top: 80px;
      padding: 30px 20px;
      @include w-flex;
      @include align-items;
      background: #FFFFFF;
      border-radius: 0px 0px 10px 10px;
      .worship-avater {
        width: 120px;
        overflow: hidden;
        margin-right: 21px;
        height: 120px;
        img {
          width: 100%;
          height: 100%;
          margin: 0 auto;
        }
      }
      .worship-body {
        flex: 1;
        .worship-name {
          @include w-flex;
          justify-content: space-between;
          .worship-btn {
            button {
              width: 103px;
              height: 40px;
              background: linear-gradient(-90deg, #79AAD9 0%, #407DB2 100%);
              border-radius: 20px;
              border: none;
              font-size: 18px;
              font-weight: 400;
              color: #FFFFFF;
            }:first-child{margin-right: 19px}
          }
        }
        .worship-date {
          font-size: 20px;
          font-weight: 400;
          color: #888888;
          line-height: 26px;
          margin-bottom: 30px;
        }
        .worship-tabs {
          margin-left: -18px;
          @include w-flex;
          .tab-router {
            position: relative;
            -webkit-box-flex: 1;
            -webkit-flex: 1;
            flex: 1;
            box-sizing: border-box;
            min-width: 0;
            color: #646566;
            text-align: center;
            padding-bottom: 10px;
            font-size: 20px;
            cursor: pointer;
            a {
              font-size: 20px;
              font-weight: 400;
              color: #888888;
            }:hover {color: #407DB2}
          }
          .tab-active {
            border-bottom: 2px solid #6a86ff;
            transition-duration: .5s;
            -moz-transition-duration: .5s;
            -webkit-transition-duration: .5s;
            -o-transition-duration: .5s;
            a {
              color: #407DB2;
            }
          }
        }
      }
    }
  }
}
</style>
